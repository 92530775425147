import * as React from "react";
import Page from "../containers/Page";
import Hero from "../components/Hero";
import Stripes from "../images/stripes.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../style/custom-style.css";

import Calender from "../images/calendar.svg";
import Envelope from "../images/envelope.svg";
import Keyboard from "../images/keyboard.svg";
import Logistics from "../images/logistics.svg";

import Invoice from "../images/invoice.svg";
import Supplier from "../images/supplier.svg";
import Money from "../images/money.svg";
import Software from "../images/software.svg";

import Presentation from "../images/presentation.svg";
import Translation from "../images/translation.svg";
import Letter from "../images/letter.svg";
import Lead from "../images/lead.svg";

// markup
const OverFlexoffice = (props) => {
  React.useEffect(() => {
    if (props.location?.state?.anchor) {
      const el = document.getElementById(props.location.state.anchor);
      window.scrollTo(0, el.offsetTop);
    }
  });
  return (
    <Page>
      <Hero small={true}>
        <h1>
          Wat FlexOffice <br />
          voor <em className="blue">u</em> kan doen
        </h1>
      </Hero>
      <div className="container-fluid">
        <div className="container">
          <section className="mt-5">
            <div className="stripes">
              <img src={Stripes} alt="" />
            </div>

            <div className="intro">
              <p></p>
            </div>
          </section>
        </div>
        <section className="mt-5 line-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-10">
                <h2>Onze services</h2>
                <small>Wat kan ik voor u doen?</small>
              </div>
            </div>

            <div className="mt-4" id="algemene-administratie">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card nopointer shadow-sm">
                    <div className="card-body">
                      <h4 className="card-title">Algemene administratie</h4>
                      <div className="row">
                        <div className="col-sm-12 col-lg-6">
                          <p className="card-text">
                            <ul>
                              <li>Agendabeheer</li>
                              <li> Mailbeheer</li>
                              <li>
                                Organiseren van vergaderingen en verslagen
                                opmaken
                              </li>
                              <li>Data-input</li>
                              <li>
                                Plaatsen en opvolging van aankoopbestellingen
                              </li>
                              <li>Logistieke organisatie</li>
                            </ul>
                          </p>
                        </div>
                        <div
                          className="col-sm-12 col-lg-6"
                          style={{ position: "relative" }}
                        >
                          <img
                            alt="Kalender icoon"
                            src={Calender}
                            className="shadow service-badge"
                            style={{ top: "-90px", right: "20px" }}
                          />
                          <img
                            alt="Enveloppe icoon"
                            src={Envelope}
                            className="shadow service-badge"
                            style={{ top: "-50px", right: "260px" }}
                          />
                          <img
                            alt="Toetsenbord icoon"
                            src={Keyboard}
                            className="shadow service-badge"
                            style={{ top: "70px", right: "90px" }}
                          />
                          <img
                            alt="Logistiek icoon"
                            src={Logistics}
                            className="shadow service-badge"
                            style={{ top: "60px", left: "120px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4" id="financiele-administratie">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card nopointer shadow-sm sh-1">
                    <div className="card-body">
                      <h4 className="card-title">Financiële administratie</h4>
                      <div className="row">
                        <div className="col-sm-12 col-lg-6">
                          <p className="card-text">
                            <ul>
                              <li>Correcte facturatie</li>
                              <li>Budgettering</li>
                              <li>Klaarzetten van betalingen</li>
                              <li>Opvolging leveranciers</li>
                              <li>Debiteurenbeheer</li>
                              <li>Inboeken facturen in facturatiesoftware</li>
                            </ul>
                          </p>
                        </div>
                        <div
                          className="col-sm-12 col-lg-6"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={Invoice}
                            alt="Factuur icoon"
                            className="shadow service-badge"
                            style={{ top: "-50px", right: "-20px" }}
                          />
                          <img
                            src={Supplier}
                            alt="Leverancier icoon"
                            className="shadow service-badge"
                            style={{ top: "30px", right: "130px" }}
                          />
                          <img
                            src={Money}
                            alt="Geld icoon"
                            className="shadow service-badge"
                            style={{ top: "110px", left: "230px" }}
                          />
                          <img
                            src={Software}
                            alt="Software icoon"
                            className="shadow service-badge"
                            style={{ top: "-100px", left: "260px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 mb-5" id="commerciele-administratie">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card nopointer shadow-sm sh-1">
                    <div className="card-body">
                      <h4 className="card-title">Commerciële administratie</h4>
                      <div className="row">
                        <div className="col-sm-12 col-lg-6">
                          <p className="card-text">
                            <ul>
                              <li>Presentaties opmaken </li>
                              <li>Onderhoud website </li>
                              <li>Opvolging van leads </li>
                              <li>Offerte opmaak en opvolging</li>
                              <li>Bestelbonnen opmaken en opvolgen</li>
                              <li>Commerciële brieven opmaken </li>
                              <li>
                                Vertalingen van anderstalige communicatie (FR,
                                DE, EN)
                              </li>
                            </ul>
                          </p>
                        </div>
                        <div
                          className="col-sm-12 col-lg-6"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={Presentation}
                            alt="Presentatie icoon"
                            className="shadow service-badge"
                            style={{ top: "-20px", right: "70px" }}
                          />
                          <img
                            src={Letter}
                            alt="Brief icoon"
                            className="shadow service-badge"
                            style={{ top: "-70px", right: "340px" }}
                          />
                          <img
                            src={Translation}
                            alt="Vertaling icoon"
                            className="shadow service-badge"
                            style={{ bottom: "10px", right: "-20px" }}
                          />
                          <img
                            src={Lead}
                            alt="Klantenopvolging icoon"
                            className="shadow service-badge"
                            style={{ top: "120px", left: "300px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default OverFlexoffice;
